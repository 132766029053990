import React from 'react';


class EmailLink extends React.Component {
  state = {}

  componentDidMount() {
    this.setState({
      joint: '\u0040',
      server: 'culkafruit.com',
    });
  }

  render() {
    const { joint, server } = this.state;
    const { type, to, className } = this.props;

    const addr = `${to}${joint}${server}`;
    const href = 'mail' + `to:${addr}`;

    if (type === 'button') {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer"
          className={"btn btn-icon btn-3 " + className}
        >
          <span className="btn-inner--icon">
            <i className="fa fa-envelope"></i>
          </span>
          <span className="btn-inner--text">
            {addr}
          </span>
        </a>
      );
    }

    return (<a
      href={href}
      target="_blank" rel="noopener noreferrer"
      className={className}
    >
      {addr}
    </a>);
  }
}

const scrollTo = (selector) => {
  const target = document.querySelector(selector);
  if (target) {
    window.scroll({
      left: 0,
      top: target.offsetTop - 56,
      behavior: 'smooth',
    });
  }
}

export { EmailLink, scrollTo };
