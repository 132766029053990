import React from 'react';
import { Container, Row, Col } from 'reactstrap';


class Footer extends React.Component {

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            {children}
            {children && <hr />}
            <Row className="align-items-center justify-content-md-between">
              <Col md="12">
                <div className="copyright">
                  &copy;&thinsp;2020 Culka Fruit
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }

}

export default Footer;
