import React from 'react';
import { Link } from 'gatsby';
import { UncontrolledCollapse, NavbarBrand, Navbar as BNavbar, NavItem, NavLink,
  Nav, Container, Row, Col, UncontrolledTooltip } from 'reactstrap';


class Navbar extends React.Component {
  state = { fixed: false }

  componentDidMount() {
    document.addEventListener('scroll', this.checkScroll);
  }

  checkScroll = (event) => {
    const scroll = window.scrollY;
    const offset = document.querySelector('main > .section-shaped').offsetHeight;
    if (scroll > offset) {
      if (!this.state.fixed) { this.setState({ fixed: true }); }
    } else if (this.state.fixed) {
      this.setState({ fixed: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.checkScroll);
  }

  render() {
    return (
      <React.Fragment>
        <header className="header-global">
          <BNavbar
            className={"navbar-main navbar-transparent navbar-light " + (this.state.fixed && 'pinned')}
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img alt="culkafruit logo"
                   src={require('../assets/img/culkafruit.png')} />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse navbar toggler="#navbar_global">
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("../assets/img/culkafruit-green.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  {this.props.children}
                  <Link to="/contacts/" className="nav-link nav-link-icon"
                      activeClassName="active">
                    <i className="fa fa-address-book d-lg-none mr-2" />
                    <span className="nav-link-inner--text">Kontakty</span>
                  </Link>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      id="nav-facebook"
                      className="nav-link-icon"
                      href="https://www.facebook.com/culkafruit"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={.5} target="nav-facebook">
                      Pozrite si náš Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="nav-instagram"
                      className="nav-link-icon"
                      href="https://www.instagram.com/culkafruit/"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={.5} target="nav-instagram">
                      Pozrite si náš Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      id="nav-map"
                      className="nav-link-icon"
                      href="https://g.page/zelovocdd"
                      target="_blank" rel="noopener noreferrer"
                    >
                      <i className="fa fa-map-marker" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Mapa
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={.5} target="nav-map">
                      Kde nás najdete?
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </BNavbar>
        </header>
      </React.Fragment>
    );
  }
}

export default Navbar;
